import axios from "axios";
import $router from "@/router/index.js";
import { ElMessage } from "element-plus";

const service = axios.create({
    timeout: 10000,
    withCredentials: true,
    baseURL: process.env.VUE_APP_BASE_URL
});

// axios拦截器
service.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => {
        if (response.data.code !== 200) {
            ElMessage.error({
                message: response.data.message,
                grouping: true
            });
        }

        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                ElMessage.error({
                    message: "登录状态失效，请重新登录！",
                    grouping: true
                });
                $router.push({ name: "Login" });
            }
            return Promise.reject(error.response);
        }
    }
);

// 请求封装
export function httpPost(url, params, config) {
    return service({
        method: "post",
        url: url,
        data: params,
        ...config
    });
}

export function httpGet(url, params, config) {
    return service({
        method: "get",
        url: url,
        params: params,
        ...config
    });
}

export function httpPut(url, params, config) {
    return service({
        method: "put",
        url: url,
        data: params,
        ...config
    });
}

export function httpDelete(url, params, config) {
    return service({
        method: "delete",
        url: url,
        data: params,
        ...config
    });
}
