<template>
    <router-view></router-view>
</template>

<script setup></script>

<style lang="scss">
@import "./assets/style/style.scss";
@import "//at.alicdn.com/t/font_2064461_uxgjly3lxj.css";
</style>
