import { clearLocalStorage, delCookie, getCookie, getLocalStorage, setCookie, setLocalStorage } from "@/utils/storage";
import { GET_USER_INFO } from "@/api/auth";

export default {
    state: {
        token: getCookie("token") ? getCookie("token") : "",
        userData: getLocalStorage("userData") ? getLocalStorage("userData") : ""
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        getUserData(state) {
            return state.userData;
        }
    },
    mutations: {
        setToken(state, value) {
            state.token = value;
            setCookie("token", value, 2592000);
        },
        setUserData(state, value) {
            state.userData = value;
            setLocalStorage("userData", value);
        },
        delToken(state) {
            state.token = "";
            delCookie("token");
        },
        delUserData(state) {
            state.userData = "";
            clearLocalStorage("userData");
        }
    },
    actions: {
        actionUserData({ getters, commit }) {
            return new Promise((resolve) => {
                GET_USER_INFO({}).then((res) => {
                    if (res.data.code === 200) {
                        commit("setUserData", res.data.data);
                        resolve(res.data.data);
                    }
                });
            });
        }
    }
};
