export default [
    // 登录
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Auth/Login.vue")
    },
    // 主体
    {
        path: "/",
        redirect: "/equipment/map",
        name: "Layout",
        component: () => import("../views/Layout/Layout.vue"),
        children: [
            // 权限模块
            {
                path: "/user/list",
                name: "UserList",
                meta: {
                    title: "用户管理",
                    breadcrumb: ["用户管理"]
                },
                component: () => import("../views/Container/Permission/User/UserList.vue")
            },
            {
                path: "/role/list",
                name: "RoleList",
                meta: {
                    title: "角色管理",
                    breadcrumb: ["角色管理"]
                },
                component: () => import("../views/Container/Permission/Role/RoleList.vue")
            },
            {
                path: "/permission/list",
                name: "permissionList",
                meta: {
                    title: "权限管理",
                    breadcrumb: ["权限管理"]
                },
                component: () => import("../views/Container/Permission/Permission/PermissionList.vue")
            },
            // 序列号模块
            {
                path: "/serial_number/list/factory",
                name: "SerialNumberListFactory",
                meta: {
                    title: "序列号管理-生产厂家",
                    breadcrumb: ["序列号管理", "生产厂家"],
                    type: 1
                },
                component: () => import("../views/Container/SerialNumber/List/SerialNumberList.vue")
            },
            {
                path: "/serial_number/list/assets",
                name: "SerialNumberListAssets",
                meta: {
                    title: "序列号管理-资产管理",
                    breadcrumb: ["序列号管理", "资产管理"],
                    type: 2
                },
                component: () => import("../views/Container/SerialNumber/List/SerialNumberList.vue")
            },
            {
                path: "/serial_number/rule/list",
                name: "SerialNumberRuleList",
                meta: {
                    title: "序列号规则",
                    breadcrumb: ["序列号管理", "规则管理"]
                },
                component: () => import("../views/Container/SerialNumber/Rule/SerialNumberRuleList.vue")
            },
            // 资产模块
            {
                path: "/asset/wire_pole/list",
                name: "AssetWirePole",
                meta: {
                    title: "电杆资产管理",
                    breadcrumb: ["资产管理", "电杆资产管理"]
                },
                component: () => import("../views/Container/Asset/WirePole/AssetWirePole.vue")
            },
            // 货物模块
            {
                path: "/goods/send/list",
                name: "GoodsSend",
                meta: {
                    title: "发货管理",
                    breadcrumb: ["货物管理", "发货管理"]
                },
                component: () => import("../views/Container/Goods/Send/GoodsSend.vue")
            },
            {
                path: "/goods/receive/list",
                name: "GoodsReceive",
                meta: {
                    title: "收货管理",
                    breadcrumb: ["货物管理", "收货管理"]
                },
                component: () => import("../views/Container/Goods/Receive/GoodsReceive.vue")
            },
            {
                path: "/goods/install/list",
                name: "GoodsInstall",
                meta: {
                    title: "安装管理",
                    breadcrumb: ["货物管理", "安装管理"]
                },
                component: () => import("../views/Container/Goods/Install/GoodsInstall.vue")
            },
            {
                path: "/goods/after_sale/list",
                name: "GoodsAfterSale",
                meta: {
                    title: "售后管理",
                    breadcrumb: ["货物管理", "售后管理"]
                },
                component: () => import("../views/Container/Goods/AfterSale/GoodsAfterSale.vue")
            },
            {
                path: "/goods/approval/list",
                name: "GoodsApproval",
                meta: {
                    title: "审批管理",
                    breadcrumb: ["货物管理", "审批管理"]
                },
                component: () => import("../views/Container/Goods/Approval/GoodsApproval.vue")
            },
            {
                path: "/alarm/list",
                name: "Alarm",
                meta: {
                    title: "报警管理",
                    breadcrumb: ["报警管理"]
                },
                component: () => import("../views/Container/Alarm/Alarm.vue")
            },
            {
                path: "/patrol/list",
                name: "Patrol",
                meta: {
                    title: "巡更管理",
                    breadcrumb: ["巡更管理"]
                },
                component: () => import("../views/Container/Patrol/Patrol.vue")
            },
            {
                path: "/equipment/online/list",
                name: "EquipmentOnline",
                meta: {
                    title: "在线电杆管理",
                    breadcrumb: ["在线电杆管理"]
                },
                component: () => import("../views/Container/Equipment/Online/EquipmentOnline.vue")
            },
            {
                path: "/equipment/terminal/list",
                name: "EquipmentTerminal",
                meta: {
                    title: "智能终端设备管理",
                    breadcrumb: ["智能终端设备管理"]
                },
                component: () => import("../views/Container/Equipment/Terminal/EquipmentTerminal.vue")
            },
            // 在线电杆定位
            {
                path: "/equipment/map",
                name: "EquipmentMap",
                meta: {
                    title: "在线电杆定位",
                    breadcrumb: ["在线电杆定位"]
                },
                component: () => import("../views/Container/Equipment/Map/EquipmentMap.vue")
            }
        ]
    }
];
