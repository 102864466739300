import { httpPost } from "./request";
import $store from "../store/index.js";

// 登录
export function AUTH_LOGIN(params) {
    return httpPost("/auth/login", params, {
        headers: {}
    });
}

// 获取用户信息
export function GET_USER_INFO(params) {
    return httpPost("/auth/me", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}
