<template>
    <div v-if="config && config.length > 0" class="geekqoo-searchbar">
        <div v-for="(item, index) in config.slice(0, foldable ? fold.number : config.length)" class="tool-item">
            <span class="item-label">{{ item.label }}：</span>
            <el-input
                v-if="item.type === 'input'"
                v-model="form[item.prop]"
                :placeholder="`请输入${item.label}`"
                class="item-input"
                clearable
                @clear="form[item.prop] = null"
            ></el-input>
            <el-select
                v-if="item.type === 'select'"
                v-model="form[item.prop]"
                :placeholder="`请选择${item.label}`"
                class="item-select"
                clearable
                filterable
                @clear="form[item.prop] = null"
            >
                <el-option
                    v-for="citem in options[item.prop]"
                    :key="citem.value"
                    :label="citem.label"
                    :value="citem.value"
                ></el-option>
            </el-select>
            <el-radio-group v-if="item.type === 'radio'" v-model="form[item.prop]" class="item-radio">
                <el-radio v-for="citem in options[item.prop]" :label="citem.value">{{ citem.label }}</el-radio>
            </el-radio-group>
            <el-date-picker
                v-if="item.type === 'date' || item.type === 'datetime'"
                v-model="form[item.prop]"
                :placeholder="`请选择${item.label}`"
                :type="item.type"
                class="item-datepicker"
            >
            </el-date-picker>
        </div>
        <slot name="tool"></slot>
        <div class="tool-item tool-buttons">
            <el-button type="primary" @click="onSearch">搜索</el-button>
            <el-button @click="onReset">重置</el-button>
            <el-button v-if="foldable" class="text-button" type="text" @click="onFold">
                <span>{{ fold.status ? "展开" : "收起" }}</span>
                <i :class="fold.status ? 'icon-down' : 'icon-up'" class="iconfont"></i>
            </el-button>
        </div>
    </div>
</template>

<script setup>
import _lodash from "lodash";
import _dayjs from "dayjs";
import { ref } from "vue";

let props = defineProps({
    config: Array,
    options: Object,
    form: Object,
    foldable: {
        type: Boolean,
        default: true
    },
    foldDisplayNumber: {
        type: Number,
        default: 3
    }
});

let emit = defineEmits(["update:form", "search", "reset", "fold"]);

// 搜索功能
let onSearch = async () => {
    let cloneForm = _lodash.cloneDeep(props.form);
    props.config.forEach((item) => {
        if (cloneForm[item.prop]) {
            if (item.type === "date") {
                cloneForm[item.prop] = _dayjs(cloneForm[item.prop]).format("YYYY-MM-DD");
            } else if (item.type === "datetime") {
                cloneForm[item.prop] = _dayjs(cloneForm[item.prop]).format("YYYY-MM-DD hh:mm:ss");
            }
        }
    });
    await emit("update:form", cloneForm);
    emit("search", cloneForm);
};

// 重置功能
let onReset = async () => {
    let cloneForm = _lodash.cloneDeep(props.form);
    Object.keys(cloneForm).forEach((item) => {
        cloneForm[item] = null;
    });
    await emit("update:form", cloneForm);
    emit("reset", cloneForm);
};

// 折叠功能
let fold = ref({
    status: true,
    number: props.foldDisplayNumber
});

let onFold = () => {
    fold.value.status = !fold.value.status;
    fold.value.number = fold.value.status ? props.foldDisplayNumber : props.config.length;
    emit("fold", fold.value.status);
};
</script>

<style lang="scss">
.geekqoo-searchbar {
    background: #fff;
    margin-bottom: 10px;
    padding: 20px 0 0 0;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    box-shadow: 0 0 10px rgba(#999, 0.1);

    .tool-item {
        flex: 0 0 20%;
        margin-bottom: 20px;
        font-size: 0;
        padding: 0 20px;
        @media screen and (max-width: 1920px) {
            flex: 0 0 25%;
        }
        @media screen and (max-width: 1366px) {
            flex: 0 0 50%;
        }
        @media screen and (max-width: 768px) {
            flex: 0 0 100%;
        }

        .item-label {
            display: inline-block;
            vertical-align: middle;
            width: 90px;
            line-height: 40px;
            text-align: left;
            font-size: 14px;
            font-weight: bold;
            color: #404040;
        }

        .item-input {
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            width: calc(100% - 100px);
        }

        .item-select {
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            width: calc(100% - 100px);
        }

        .item-datepicker {
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            width: calc(100% - 100px);
        }

        .item-radio {
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            width: calc(100% - 100px);

            .el-radio {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .tool-buttons {
        margin-left: auto;
        text-align: right;

        .text-button {
            span {
                font-size: 14px;
            }

            i {
                margin-left: 5px;
                font-size: 14px;
            }
        }
    }
}
</style>
