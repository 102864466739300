import { clearLocalStorage, getLocalStorage, setLocalStorage } from "@/utils/storage";

export default {
    state: {
        navigation: getLocalStorage("navigation") ? getLocalStorage("navigation") : []
    },
    getters: {
        getNavigation(state) {
            return state.navigation;
        }
    },
    mutations: {
        setNavigation(state, value) {
            state.navigation = value;
            setLocalStorage("navigation", value);
        },
        delNavigation(state) {
            state.navigation = "";
            clearLocalStorage("navigation");
        }
    },
    action: {}
};
