<template>
    <el-pagination
        :currentPage="currentPage"
        :pageSize="pageSize"
        :background="background"
        :layout="layout"
        :total="total"
        @update:pageSize="pageSizeChange"
        @update:currentPage="pageCurrentPage"
    >
    </el-pagination>
</template>

<script setup>
let props = defineProps({
    currentPage: { type: Number },
    pageSize: { type: Number },
    total: { type: Number },
    background: {
        type: Boolean,
        default: true
    },
    layout: {
        type: String,
        default: "total, sizes, prev, pager, next, jumper"
    }
});

let emit = defineEmits(["update:currentPage", "update:pageSize", "change"]);

let pageSizeChange = (val) => {
    emit("update:pageSize", val);
    emit("change");
};

let pageCurrentPage = (val) => {
    emit("update:currentPage", val);
    emit("change");
};
</script>

<style scoped></style>
