import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";

// element-plus
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";

// 私人组件
import GkSearchbar from "./components/GkSearchbar/GkSearchbar.vue";
import GkPagination from "./components/GkPagination/GkPagination.vue";

router.beforeEach((to, from, next) => {
    let navigation = store.getters.getNavigation;
    let navigationItem = { title: to.meta.title, name: to.name };

    if (navigationItem.title) {
        if (navigation.length > 0) {
            let isExist = false;
            navigation.forEach((item) => {
                if (item.title === navigationItem.title) isExist = true;
            });
            if (!isExist) navigation.push(navigationItem);
        } else {
            navigation.push(navigationItem);
        }
    }

    store.commit("setNavigation", navigation);

    next();
});

const app = createApp(App);

app.component("GkSearchbar", GkSearchbar);
app.component("GkPagination", GkPagination);

app.use(ElementPlus, { locale: zhCn }).use(router).use(store).mount("#app");
